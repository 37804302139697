import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  list: [],
  status: requestStatus.UNDONE,
  error: ''
};

const rooms = (state = initialState, action) => {
  switch (action.type) {
    case types.FIND_ALL_ROOMS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.FIND_ALL_ROOMS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: requestStatus.DONE,
        error: ''
      };

    case types.FIND_ALL_ROOMS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.CREATE_ROOM_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.CREATE_ROOM_SUCCESS:
      const payload = action.payload;
      const newRooms = Array.isArray(payload) ? payload : [payload];

      return {
        ...state,
        status: requestStatus.DONE,
        list: state.list.concat(newRooms),
      };

    case types.CREATE_ROOM_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    // @TODO: handle errors
    case types.UPDATE_ROOM_SUCCESS:
      return {
        ...state,
        list: state.list.map(room => {
          return room.id === action.payload.id ? action.payload : room;
        })
      };

    // @TODO: handle errors
    case types.DELETE_ROOM_SUCCESS:
      return {
        ...state,
        list: state.list.filter(room => {
          return room.id !== action.payload.id;
        })
      };

    default:
      return state;
  }
};

export default rooms;
