import React, { useState } from 'react';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { useDispatch } from 'redux-react-hook';
import CloseModalButton from './modals/close-modal-button';
import AdaptiveModalFooter from './adaptive-modal-footer';
import FileUploader from '../upload/file-uploader';
import ImportExportService from '../../services/ImportExportService';
import { updateEntityProgress } from '../../store/actions/progress';
import types from '../../store/types';
import { reloadSessions } from '../../store/actions/sessions';

const ImportErrors = ({ error, rejected }) => {
  const { t } = useTranslation();

  const hasRejected = !!rejected && rejected.length > 0;

  if (!hasRejected && !error) {
    return null;
  }

  const { message, details } = error || {};

  return (
    <div
      style={{
        color: 'var(--red-2)',
        padding: '10px',
        marginBottom: 0
      }}
    >
      <p style={{ marginBottom: '10px' }}>{message}</p>
      {!!details && <p style={{ marginBottom: '10px' }}>{t('import:error:details')} {details}</p>}
      {hasRejected && (
        <ul style={{ margin: 0 }}>
          {rejected.map((item) => <li key={item.id}>{item.name}</li>)}
        </ul>
      )}
    </div>
  );
};

const getImportSuccessAction = (entityName, dispatch) => {
  return {
    rooms: ({ data }) => dispatch({
      type: types.FIND_ALL_ROOMS_SUCCESS,
      payload: data
    }),
    speakers: ({ data }) => dispatch({
      type: types.FIND_ALL_SPEAKERS_SUCCESS,
      payload: data
    }),
    sessions: ({ eventId }) => dispatch(reloadSessions(eventId)),
  }[entityName];
};

const XlsxImport = ({ eventId, entityName, disabled }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [rejected, setRejected] = useState([]);
  const [error, setError] = useState();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDropFile = (acceptedFiles) => {
    setRejected([]);
    setError(null);
    setFile(acceptedFiles[0]);
  };

  const handleClose = () => {
    setFile(null);
    setRejected([]);
    setError(null);
    setModalOpen(false);
  };

  const handleUpload = () => {
    setUploading(true);

    ImportExportService.import(eventId, entityName, file)
      .then(({ data, deleted, rejected }) => {
        if ((data && data.length > 0) || (deleted && deleted.length > 0)) {
          dispatch(updateEntityProgress(entityName, true));

          getImportSuccessAction(entityName, dispatch)({ data, eventId });
        }

        if (rejected && rejected.length > 0) {
          setRejected(rejected);

          setError({
            message: t('import:error:rejected')
          });

          return;
        }

        setModalOpen(false);
      })
      .catch(({ data }) => {
        const errorMessage = data && data.hint
          ? `import:error:${data.hint}`
          : 'import:error:unknown';

        setError({
          message: t(errorMessage),
          details: data && data.message
        });
      })
      .finally(() => {
        setUploading(false);
        setFile(null);
      });
  };

  const btnTip = disabled ? 'common:errors.past_event' : `import:${entityName}:button`;

  return (
    <>
      {modalOpen && (
        <ModalTransition>
          <Modal
            shouldScrollInViewport
            shouldCloseOnOverlayClick={false}
            onClose={handleClose}
          >
            <ModalHeader>
              <ModalTitle>
                {t(`import:${entityName}:header`)}
              </ModalTitle>
              <CloseModalButton
                onClick={handleClose}
                isDisabled={uploading}
              />
            </ModalHeader>
            <ModalBody>
              <FileUploader
                file={file}
                onDrop={handleDropFile}
                onDelete={() => setFile(null)}
                uploading={uploading}
              />
              <ImportErrors error={error} rejected={rejected} />
            </ModalBody>
            <AdaptiveModalFooter>
              <Button
                appearance='primary'
                isDisabled={!file}
                isLoading={uploading}
                onClick={handleUpload}
              >
                {t(`import:submit_upload`)}
              </Button>
            </AdaptiveModalFooter>
          </Modal>
        </ModalTransition>
      )}
      <Button
        data-tip={t(btnTip)}
        iconBefore={<UploadIcon label="upload-icon" />}
        style={{ marginRight: 10 }}
        onClick={() => setModalOpen(true)}
        isDisabled={disabled}
      />
    </>
  );
};

export default XlsxImport;
