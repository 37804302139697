import { createSelector } from 'reselect';
import { getNotificationsAllowed } from './upgrade';
import { getSelectedConf } from './confs';
import { getZonedNotificationData } from '../../services/SessionsFiltersService';

const notificationList = state => state.notifications.list;

export const getRemainingNotifications = createSelector(
  notificationList,
  getNotificationsAllowed,
  (notifications, allowed) => {
    return allowed - notifications.length;
  }
);

export const selectNotifications = createSelector(
  notificationList,
  getSelectedConf,
  (notifications, selectedEvent) => {
    const timeZone = selectedEvent.timezone;

    return notifications.map((notification) => getZonedNotificationData(notification, timeZone));
  }
);
