import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { DateTimePicker, TimePicker } from '@atlaskit/datetime-picker';
import { Field, ErrorMessage, HelperMessage } from '@atlaskit/form';
import Select, { CreatableSelect } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';
import { parse, startOfDay, setHours, startOfHour, addHours } from 'date-fns/esm';
import { isEmpty } from 'lodash';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Tick from '@atlaskit/icon/glyph/check-circle';

import { requestStatus } from '../../../store/request-status';
import { findRooms } from '../../../store/actions/rooms';
import { findSpeakers } from '../../../store/actions/speakers';
import { isDate } from '../../utils/dates';
import { format, getDateRange } from '../../../services/DatesService';
import { getAllTags, getSelectedDay } from '../../../store/selectors/sessions';
import { SizedTextfield, SizedTextarea } from '../../utils/sized-textfield';
import {
  getOverlappingSessionsForSession,
  getOverlappingSessionsByRoom,
  getOverlappingSessionsBySpeakers,
  getOverlappingSpeakers,
  getOverlappingDateString,
  isDayOutOfRange
} from '../../../services/SessionsFiltersService';
import i18n, { LOCALES_MAP } from '../../../i18n/i18n';
import MiniApps from './mini-apps';
import Tip from '../../utils/tip';
import { getTimeFormat } from '../../../store/selectors/account';
import { MAX_SESSION_DESC_CHARS } from '../../../constants';
import Registration from './registration';
import DEFAULT_TIMES_15_MIN from '../../utils/default-times-15-min';

const FieldsWrapper = styled.div`
  font-weight: 500;
  
  & > div {
    margin-bottom: 15px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TileImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
`;

const formatOptionLabel = user => {
  return (
    <SelectWrapper>
      <TileImg alt="tile" src={user.picture} />
      <span style={{ marginLeft: 10 }}>{user.label}</span>
    </SelectWrapper>
  );
};

const mapState = state => ({
  rooms: state.rooms.list,
  roomsStatus: state.rooms.status,
  sessions: state.sessions.list,
  speakers: state.speakers.list,
  speakersStatus: state.speakers.status,
  selectedDay: getSelectedDay(state),
  tags: getAllTags(state),
  timeFormat: getTimeFormat(state)
});

const emptySession = {
  apps: []
};

const SessionForm = ({ selectedConf = {}, session = emptySession }) => {
  const { t } = useTranslation();
  const validDateRange = getDateRange(selectedConf.from, selectedConf.to);

  const dispatch = useDispatch();

  const {
    rooms,
    roomsStatus,
    selectedDay,
    // tags,
    sessions,
    speakers,
    speakersStatus,
    timeFormat
  } = useMappedState(mapState);

  const [roomValid, setRoomValid] = useState(null);
  const [speakersValid, setSpeakersValid] = useState(null);
  const apps = session.apps || emptySession.apps;

  useEffect(() => {
    if (roomsStatus === requestStatus.UNDONE) {
      dispatch(findRooms(selectedConf.id));
    }

    if (speakersStatus === requestStatus.UNDONE) {
      dispatch(findSpeakers(selectedConf.id));
    }
  }, [dispatch, roomsStatus, speakersStatus, selectedConf.id]);

  const defaultSpeakersValue = (session.speakers || []).map(speaker => ({
    value: speaker.id,
    label: speaker.name,
    picture: speaker.picture
  }));

  const defaultFrom = (() => {
    const today = new Date();

    const from = selectedDay && !isDayOutOfRange(selectedDay, selectedConf.from, selectedConf.to)
      ? selectedDay
      : new Date(selectedConf.from);

    return from > today
      ? startOfHour(setHours(from, today.getHours()))
      : startOfHour(addHours(today, 1));
  })();

  const getDatesRangeError = (date, from, to) => {
    // check if the date is after the start date of the conference
    const confFrom = new Date(from);

    if (date < startOfDay(confFrom)) {
      return t('sessions:form:errors:past_conf', { date: format(confFrom, 'P') });
    }

    // check if the date is before the end date of the conference
    const confTo = new Date(to);

    if (startOfDay(date) > startOfDay(confTo)) {
      return t('sessions:form:errors:after_conf', { date: format(confTo, 'P') });
    }

    return null;
  }

  return (
    <FieldsWrapper key={session.id}>
      <Field
        label={t('sessions:form:name')}
        name="name"
        defaultValue={session.name || ''}
        isRequired
      >
        {({ fieldProps }) => {
          return <SizedTextfield maxLength={100} {...fieldProps} autoFocus />;
        }}
      </Field>
      <Field label={t('sessions:form:desc')} name="desc" defaultValue={session.desc || ''}>
        {({ fieldProps }) => <SizedTextarea maxLength={MAX_SESSION_DESC_CHARS} {...fieldProps} />}
      </Field>
      <Field
        label={t('sessions:form:from')}
        validate={(val, form) => {
          // check if the date is invalid
          if (!isDate(val)) {
            return t('sessions:form:errors:date');
          }

          const from = new Date(val);

          // check if the date is before today
          if (from < new Date()) {
            return t('sessions:form:errors:past');
          }

          const datesRangeError = getDatesRangeError(from, selectedConf.from, selectedConf.to);

          if (datesRangeError) {
            return datesRangeError;
          }

          // check if the `from` is after the `to`
          if (form.to && from > parse(form.to, 'HH:mm', from)) {
            return t('sessions:form:errors:start_time_after');
          }

          // automatically set the `to` 1h after `from` if it's not set
          if (isEmpty(form.to)) {
            from.setHours(from.getHours() + 1);
            form.to = format(from, 'HH:mm');
          }
          return null;
        }}
        name="from"
        defaultValue={session.from || defaultFrom.toISOString()}
        isRequired
      >
        {({ fieldProps, error, meta: { touched } }) => {
          const dateRangeError = !touched && getDatesRangeError(new Date(fieldProps.value), selectedConf.from, selectedConf.to);

          return <>
            <DateTimePicker
              {...fieldProps}
              timeIsEditable
              times={DEFAULT_TIMES_15_MIN}
              timeFormat={timeFormat}
              locale={LOCALES_MAP[i18n.language]}
              datePickerProps={validDateRange}
            />
            {dateRangeError
              ? <ErrorMessage>{dateRangeError}</ErrorMessage>
              : error
                ? <ErrorMessage>{t(error)}</ErrorMessage>
                : <Tip>{t('sessions:form:tips:hours')}</Tip>
            }
          </>
        }}
      </Field>
      <Field
        label={t('sessions:form:to')}
        validate={(val, form) => {
          try {
            const dateFrom = new Date(form.from);
            const date = parse(val, 'HH:mm', dateFrom);
            if (date <= dateFrom) return 'sessions:form:errors:end_time_before';
            return null;
          } catch (err) {
            return 'sessions:form:errors:date';
          }
        }}
        name="to"
        defaultValue={session.to ? format(new Date(session.to), 'HH:mm') : ''}
        isRequired
      >
        {({ fieldProps, error }) => (
          <>
            <TimePicker
              {...fieldProps}
              timeIsEditable
              times={DEFAULT_TIMES_15_MIN}
              timeFormat={timeFormat}
            />
            {error ? (
              <ErrorMessage>{t(error)}</ErrorMessage>
            ) : (
              <Tip>{t('sessions:form:tips:hours')}</Tip>
            )}
          </>
        )}
      </Field>
      <Field
        label={t('sessions:form.registration')}
        name="registration_limit"
        defaultValue={session.registration_limit}
      >
        {({ fieldProps }) => (
          <Registration
            limit={session.registration_limit}
            onChange={val => fieldProps.onChange(val)}
          />
        )}
      </Field>
      <Field
        label={t('sessions:form:room')}
        name="room"
        transform={val => val} // This fix `isClearable`
        validate={(val, form) => {
          const overlappingSessionsbyRoom = getOverlappingSessionsByRoom(
            getOverlappingSessionsForSession(sessions, {
              ...session,
              ...form,
              ...{ room_id: (val || {}).value }
            }),
            (val || {}).value
          );

          if (isEmpty(overlappingSessionsbyRoom)) {
            setRoomValid(null);
            return null;
          }

          setRoomValid(
            t('sessions:form:errors:room_occupied_date', {
              room: overlappingSessionsbyRoom[0].room.name,
              name: overlappingSessionsbyRoom.map(session => session.name).join(', '),
              date: getOverlappingDateString(overlappingSessionsbyRoom, timeFormat)
            })
          );
        }}
        defaultValue={session.room ? { label: session.room.name, value: session.room.id } : null}
      >
        {({ fieldProps }) => {
          return (
            <>
              <CreatableSelect
                className="single-select"
                classNamePrefix="react-select"
                options={rooms.map(room => ({ label: room.name, value: room.id }))}
                noOptionsMessage={() => t('sessions:form:no_rooms')}
                placeholder={t('sessions:form:choose_room')}
                isLoading={roomsStatus === requestStatus.WAITING}
                formatCreateLabel={input => t('common:create_input', { input })}
                LoadingMessage={() => t('common:loading')}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                {...fieldProps}
                isClearable
              />
              {(!isEmpty(roomValid) || (fieldProps.value && fieldProps.value.__isNew__)) && (
                <HelperMessage>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isEmpty(roomValid) ? (
                      <>
                        <Tick primaryColor={colors.G400} />
                        <span style={{ marginLeft: 5 }}>{t('sessions:actions:created_room')}</span>
                      </>
                    ) : (
                      <>
                        <WarningIcon primaryColor="var(--orange-3)" />
                        {roomValid}
                      </>
                    )}
                  </div>
                </HelperMessage>
              )}
            </>
          );
        }}
      </Field>
      <Field
        label={t('sessions:form:speakers')}
        name="speakers"
        validate={(value, form) => {
          const val = value || defaultSpeakersValue;

          const overlappingSessions = getOverlappingSessionsBySpeakers(
            getOverlappingSessionsForSession(sessions, {
              ...session,
              ...form,
              ...{ speakers: val.map(f => ({ id: f.value })) }
            }),
            val.map(f => f.value)
          );

          if (isEmpty(overlappingSessions)) {
            setSpeakersValid(null);
            return null;
          }

          const overlappingSpeakers = getOverlappingSpeakers(overlappingSessions, speakers);

          setSpeakersValid(
            t('sessions:form:errors:speaker_occupied_date', {
              count: overlappingSpeakers.length,
              speakers: overlappingSpeakers.join(', '),
              date: getOverlappingDateString(overlappingSessions, timeFormat),
              name: overlappingSessions.map(session => session.name).join(', ')
            })
          );
        }}
        defaultValue={defaultSpeakersValue}
      >
        {({ fieldProps }) => {
          return (
            <>
              <Select
                options={speakers.map(speaker => ({
                  value: speaker.id,
                  label: speaker.name,
                  picture: speaker.picture
                }))}
                placeholder={t('sessions:form:select_speakers')}
                noOptionsMessage={() => t('sessions:form:no_speakers')}
                isLoading={speakersStatus === requestStatus.WAITING}
                LoadingMessage={() => t('common:loading')}
                formatOptionLabel={formatOptionLabel}
                isMulti
                isClearable
                menuPortalTarget={document.body}
                styles={{
                  container: base => ({ ...base, fontFamily: 'inherit' }),
                  menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                {...fieldProps}
                onChange={val => {
                  // Workaround due to atlaskit missing value prop on field
                  session.speakers = val.map(f => ({
                    id: f.value,
                    name: f.label,
                    picture: f.picture
                  }));
                  // Call super
                  fieldProps.onChange(val);
                }}
              />
              {!isEmpty(speakersValid) && (
                <HelperMessage>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningIcon primaryColor="var(--orange-3)" />
                    {speakersValid}
                  </div>
                </HelperMessage>
              )}
            </>
          );
        }}
      </Field>
      {/*this select is buggy and need to be updated from atlassian*/}
      {/*<Field label={t('sessions:form:tags')} name="tags">*/}
      {/*  {({ fieldProps, error }) => (*/}
      {/*    <>*/}
      {/*      <CreatableSelect*/}
      {/*        className="single-select"*/}
      {/*        classNamePrefix="react-select"*/}
      {/*        placeholder={t('sessions:form:enter_tags')}*/}
      {/*        isMulti*/}
      {/*        noOptionsMessage={() => t('sessions:form:no_tags')}*/}
      {/*        options={tags.map(tag => ({*/}
      {/*          label: tag,*/}
      {/*          value: tag.toLowerCase().replace(/\W/g, '')*/}
      {/*        }))}*/}
      {/*        defaultValue={(session.tags || []).map(tag => ({*/}
      {/*          label: tag,*/}
      {/*          value: tag.toLowerCase().replace(/\W/g, '')*/}
      {/*        }))}*/}
      {/*        formatCreateLabel={input => t('common:create_input', { input })}*/}
      {/*        menuPortalTarget={document.body}*/}
      {/*        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}*/}
      {/*        {...fieldProps}*/}
      {/*      />*/}
      {/*      {error && <ErrorMessage>{t(error)}</ErrorMessage>}*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</Field>*/}
      <Field
        label={t('sessions:form.apps')}
        name="apps"
        defaultValue={apps}
      >
        {({ fieldProps }) => {
          return <MiniApps apps={apps} onChange={val => fieldProps.onChange(val)} />;
        }}
      </Field>
    </FieldsWrapper>
  );
};

export default SessionForm;
