export const setIntercomUser = ({
  email_verified,
  nickname,
  name,
  email,
  created_at,
  logins_count,
  user_metadata
}) => {
  if (process.env.REACT_APP_MODE !== 'development') {
    const { consentGiven, lang, nonProfit } = user_metadata;
    const formattedName = name && !name.includes('@') ? name : nickname;

    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      name: formattedName,
      email,
      created_at,
      emailVerified: email_verified,
      appLanguage: lang,
      nonProfit,
      consentGiven,
      loginsCount: logins_count
    });
  }
};

export const shutdownIntercom = () => {
  if (process.env.REACT_APP_MODE !== 'development') {
    window.Intercom('shutdown');
  }
};
