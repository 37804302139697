import React from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { useTranslation } from 'react-i18next';
import UploadFileImage from '../../assets/upload_file.png';
import FontAwesomeIcon from '../utils/icons/font-awesome-icon';
import RejectionFile from './rejection-file';

const DropZone = styled.section`
  padding: 40px;
  text-align: center;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: 1px dashed var(--gray-3);
  background-color: var(--gray-1);
  border-radius: 10px;
  opacity: ${props => (props.isDragActive ? 0.7 : 1 )};
  
  &:hover {
    opacity: 0.7;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Preview = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 5px 5px 5px 15px;
  border: 1px solid var(--gray-2);
  border-radius: 10px;
  opacity: ${props => (props.uploading ? 0.3 : 1 )};
`;

const Title = styled.p`
  font-size: 20px;
  margin: 20px 0 10px;
`;

const Image = styled.img`
  max-width: 100px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FileUploader = ({ file, onDrop, onDelete, uploading }) => {
  const { t } = useTranslation();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: {
      // 'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xlsx']
    },
    validator: (fileData) => {
      if (fileData.size > 1000000) {
        return {
          code: 'file-too-large'
        };
      }

      return null;
    }
  });

  if (file) {
    return (
      <Preview uploading={uploading}>
        {file.name}
        <Button appearance="subtle" onClick={onDelete}>
          <FontAwesomeIcon icon="times" />
        </Button>
        {uploading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
      </Preview>
    );
  }

  return (
    <>
      <DropZone isDragActive={isDragActive} {...getRootProps()}>
        <input {...getInputProps()} />
        <Content>
          <Image src={UploadFileImage} alt='Upload file' />
          <Title>{t('import:drop_file_header')}</Title>
          {t('import:drop_file_text')}
        </Content>
      </DropZone>
      <RejectionFile files={fileRejections} />
    </>
  );
};

export default FileUploader;
