import React from 'react';
import { head, sortBy } from 'lodash/fp';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import { useTranslation } from 'react-i18next';
import { formatDistance } from  '../../services/DatesService';
import styled from 'styled-components';

const FeaturedContent = styled.div`
  margin: 20px 20px 30px;
  
  @media (min-width: 640px) {
    margin: 25px 40px 40px;
  }
`;

// eslint-disable-next-line
export default ({ past, live, incoming }) => {
  const { t } = useTranslation();

  if (live && live.length > 0) {
    return null;
  }

  let message;

  if (incoming && incoming.length > 0) {
    const nextConf = head(sortBy(['from'], incoming));

    message = (
      <>
        {t('confs:home:featured:your_next')}{' '}
        <span style={{ fontWeight: 700 }}>"{nextConf.name}"</span>{' '}
        {t('confs:home:featured:starts_in', {
          days: formatDistance(new Date(nextConf.from), new Date())
        })}
      </>
    );
  } else if (past && past.length > 0) {
    message = t('confs:home:featured:past_event');
  }

  if (!message) {
    return null;
  }

  return (
    <div
      style={{
        maxWidth: 700,
        width: '90%',
        backgroundColor: '#fff',
        borderRadius: 10,
        boxShadow: '#dfe8f0 0px 1px 7px',
        textAlign: 'center'
      }}
    >
      <FeaturedContent>
        <div>
          <div
            style={{
              display: 'inline-block',
              backgroundColor: 'var(--green-1)',
              borderRadius: 60
            }}
          >
            <div style={{ margin: '14px 15px' }}>
              <CalendarIcon label="calendar-icon" primaryColor="var(--green-2)" size="large" />
            </div>
          </div>
        </div>
        <div style={{ fontSize: 34, marginTop: '15px' }}>
          {message}
        </div>
      </FeaturedContent>
    </div>
  );
};
