import { startOfDay } from 'date-fns/esm';
import types from '../types';
import SessionsService from '../../services/SessionsService';
import handleError from '../utils/errors';
import { updateEntityProgress } from './progress';
import { getZonedISOString } from '../../services/DatesService';

export const updateSession = session => dispatch => {
  dispatch({
    type: types.UPDATE_SESSION_REQUEST
  });
  return SessionsService.update(session)
    .then(updatedSession => {
      dispatch(updateEntityProgress('sessions', true));
      dispatch({
        type: types.UPDATE_SESSION_SUCCESS,
        payload: updatedSession
      });
    })
    .catch(err => handleError(types.UPDATE_SESSION_ERROR, err, dispatch));
};

export const createSession = session => dispatch => {
  return SessionsService.create(session).then(createdSession => {
    dispatch(updateEntityProgress('sessions', true));
    // TODO remove event?
    return dispatch({
      type: types.CREATE_SESSION,
      payload: createdSession
    });
  });
};

export const findSessions = confId => dispatch => {
  dispatch({
    type: types.FIND_ALL_SESSIONS_REQUEST
  });

  return SessionsService.findAllOfConf(confId)
    .then(sessions => {
      return dispatch({
        type: types.FIND_ALL_SESSIONS_SUCCESS,
        payload: sessions
      });
    })
    .catch(err => handleError(types.FIND_ALL_SESSIONS_ERROR, err, dispatch));
};

export const reloadSessions = (confId) => dispatch => {
  return dispatch(findSessions(confId))
    .then(sessions => {
      if (!sessions || !sessions.length) {
        return;
      }

      const { from, conf } = sessions[0];

      const sessionFrom = getZonedISOString(from, conf.timezone);
      const day = startOfDay(new Date(sessionFrom));

      dispatch(selectDay(day));
    });
};

export const deleteSession = (sessionId, remainingSessions) => dispatch => {
  return SessionsService.delete(sessionId)
    .then(() => dispatch({ type: types.DELETE_SESSION, payload: sessionId }))
    .then(() => {
      return dispatch(updateEntityProgress('sessions', remainingSessions - 1 > 0));
    });
};

export const selectDay = day => dispatch => {
  dispatch({
    type: types.SELECT_DAY,
    payload: day
  });
};

export const selectRooms = rooms => dispatch => {
  dispatch({
    type: types.SELECT_ROOMS,
    payload: rooms
  });
};

export const selectSpeakers = speakers => dispatch => {
  dispatch({
    type: types.SELECT_SPEAKERS,
    payload: speakers
  });
};

export const resetFilters = () => dispatch => {
  dispatch({ type: types.RESET_FILTERS });
};
