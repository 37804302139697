import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Error = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 15px;
  border: 1px solid var(--gray-2);
  border-radius: 10px;
  color: var(--red-2);
`;

const RejectionFile = ({ files }) => {
  const { t } = useTranslation();
  const fileRejection = files[0];

  if (!fileRejection) {
    return null;
  }

  const { file, errors } = fileRejection;
  const errorCode = errors[0].code;

  return (
    <Error>
      {t(`import:error:${errorCode}`, { name: file.name})}
    </Error>
  );
};

export default RejectionFile;
