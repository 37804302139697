import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ConfSubList from './conf-sub-list';
import { isEmpty } from 'lodash';

const Title = styled.h2`
  font-weight: 700;
  margin-bottom: 10px;
  ${({ hidden }) => (hidden ? 'display: none;' : null)}
`;

const ConfList = ({ past, live, incoming }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title hidden={isEmpty(live)}>{t('confs:list:headers:live')}</Title>
      <ConfSubList confs={live} />
      <Title hidden={isEmpty(incoming)}>{t('confs:list:headers:incoming')}</Title>
      <ConfSubList confs={incoming} />
      <Title hidden={isEmpty(past)}>{t('confs:list:headers:past')}</Title>
      <ConfSubList confs={past} />
    </>
  );
};

export default ConfList;
