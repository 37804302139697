export const SUPERADMIN_KEY = 'superadmin';
export const TOKEN_KEY = 'token';
export const IS_24_HOUR_TIME = 'is_24_hour_time';

export const ProductsTypes = {
  PLAN: 'plan',
  FEATURE: 'feature',
};

export const FEATURES_LIMITS_MAP = {
  'feature_users': 'users',
  'feature_days': 'days',
  'feature_rooms': 'rooms',
  'feature_links': 'links',
  'feature_speakers': 'speakers',
  'feature_notifications': 'notifications',
  'feature_private': 'private'
};

export const COUNTABLE_FEATURE_ID_MAP = {
  feature_users: 'max_users_per_conf',
  feature_days: 'max_days_per_conf',
  feature_rooms: 'max_rooms_per_conf',
  feature_links: 'max_links_per_conf',
  feature_speakers: 'max_speakers_per_conf',
  feature_notifications: 'notifications_per_conf',
};

export const FEATURE_ID_MAP = {
  ...COUNTABLE_FEATURE_ID_MAP,
  feature_private: 'conf_can_be_private',
};

export const UNCOUNTABLE_FEATURES = ['feature_private'];

export const UNLIMITED_VALUE = -1;

export const SESSION_APPS = ['webpage', 'slido', 'player', 'embedded'];

export const DEFAULT_TIMEZONE = 'Europe/London';

export const MASK = {
  NEXT_STEP: 'next_step',
  CREATE_ROOM: 'create_room',
  CREATE_SPEAKER: 'create_speaker',
  CREATE_LINK: 'create_link',
  CREATE_THEME: 'create_theme',
  CREATE_SESSION: 'create_session',
  EVENT_READY: 'event_ready'
};

export const DEFAULT_LINK_MODE = {
  ALWAYS: 'always',
  ONCE: 'once',
};

export const FAQ_LINK = 'https://faq.conf.app/';
export const VIDEO_GUIDE_LINK = 'https://www.youtube.com/playlist?list=PLpw2ugWnZ8TQr47WDI-0k95JKx1ww-coQ';
export const FAQ_MINI_APPS_LINK = 'https://faq.conf.app/docs/documentation/mini-apps/';
export const FAQ_EMBED_AGENDA_LINK = 'https://faq.conf.app/docs/documentation/agenda/embedded-agenda/';

export const MAX_SESSION_DESC_CHARS = 2000;

export const PLAN_IDS = {
  BASE: 'plan_base',
  PRO: 'plan_pro',
  ENTERPRISE: 'plan_enterprise',
  ULTIMATE: 'plan_ultimate',
  NON_PROFIT: 'plan_nonprofit',
};
