import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import NotificationsForm from './conf-notifications-form';
import CloseModalButton from '../../utils/modals/close-modal-button';
import { format } from '../../../services/DatesService';
import AdaptiveModalFooter from '../../utils/adaptive-modal-footer';

const getNotificationDate = (notification, timeFormat) => {
  const date = notification.sent_at;
  const dateFormat = timeFormat === 'HH:mm' ? 'dd MMM yyyy H:mm' : 'dd MMM yyyy h:mm a';

  return format(new Date(date), dateFormat);
};

const NotificationEditModal = ({ onClose, notification, onSave, timeFormat, from, to }) => {
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState(true);

  const isEditingMode = !notification.sent_at;

  return (
    <div>
      <ModalTransition>
        <Modal
          shouldScrollInViewport
          shouldCloseOnOverlayClick={false}
          onClose={onClose}
        >
          <ModalHeader>
            <ModalTitle>
              {t(`notifications:${isEditingMode ? 'edit' : 'view'}`)}
            </ModalTitle>
            <CloseModalButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            {notification.sent_at && (
              <p>
                {t('notifications:was_sent_on', { date: getNotificationDate(notification, timeFormat)})}
              </p>
            )}
            <Form onSubmit={onSave}>
              {({ formProps }) => (
                <form id="notification-form" {...formProps}>
                  <NotificationsForm
                    isEditingMode={isEditingMode}
                    notification={notification}
                    timeFormat={timeFormat}
                    onChange={(data) => setDisabled(data)}
                    from={from}
                    to={to}
                  />
                </form>
              )}
            </Form>
          </ModalBody>
          <AdaptiveModalFooter>
            <Button
              form="notification-form"
              appearance="primary"
              type="submit"
              isDisabled={!isEditingMode || isDisabled}
            >
              {t('common:save')}
            </Button>
          </AdaptiveModalFooter>
        </Modal>
      </ModalTransition>
    </div>
  );
};

export default NotificationEditModal;
