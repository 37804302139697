import React from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getItem, removeItem } from '../../services/StorageService';
import { performAuthentication } from '../../store/actions/auth';
import { getUserConsent } from '../../store/selectors/auth';
import { get24HourTime } from '../../store/actions/account';

const CallbackWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Message = styled.div`
  font-size: x-large;
  font-weight: bold;
  margin-top: 5%;
  color: var(--gray-2);
`;
const HelpMessage = styled.div`
  font-weight: bold;
  color: var(--gray-2);
`;
const MessageLink = styled(Link)`
  margin-left: 5px;

  color: var(--gray-3);
  transition: color 0.2s ease-in-out;
  &:hover {
    color: var(--gray-2);
  }
`;

const Callback = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { auth, consentGiven } = useMappedState(state => ({
    auth: state.auth,
    consentGiven: getUserConsent(state)
  }));

  const previousUri = getItem('last_uri');

  if (auth.user && auth.user.authenticated) {
    if (!consentGiven) {
      return <Redirect to={'/consent'} />;
    }

    removeItem('last_uri');

    return <Redirect to={previousUri || '/'} />;
  }

  dispatch(performAuthentication(i18n.language))
    .then(() => dispatch(get24HourTime()))
    .catch(() => {
    // sometimes, after a reload, authentication will fail
    // it might only apear on development with a lot of live-reload
    return (window.location = '/login');
  });

  return (
    <CallbackWrapper className="text-center">
      <Spinner size="large" />
      <Message>{t('auth:loading_profile')}</Message>
      <HelpMessage>
        {t('auth:help_message')}
        <MessageLink to="/">{t('auth:retry')}</MessageLink>
      </HelpMessage>
    </CallbackWrapper>
  );
}

export default Callback;
