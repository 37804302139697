import HttpService from './HttpService';

class ImportExportService extends HttpService {
  constructor() {
    super(process.env.REACT_APP_SERVICES_URL);
  }

  export(id, entityName) {
    return this.http
      .get(`/conf/${id}/export-${entityName}`, {
        responseType: 'blob', // Ensure the response is treated as a binary Blob
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  import(id, entityName, file) {
    const formData = new FormData();

    formData.append('file', file);

    return this.http
      .post(`/conf/${id}/import-${entityName}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const importExportService = new ImportExportService();

export default importExportService;

