import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Badge from '../../layout/badge';
import Info from '../../layout/info';
import Button from '@atlaskit/button';
import { FAQ_EMBED_AGENDA_LINK } from '../../../constants';

const Wrapper = styled.div`
  width: 100%;
`;

const PlanBadge = styled(Badge)`
  margin: 0 5px;
`;

const Link = styled.a`
  text-decoration: underline;
  margin-left: 2px;
  color: var(--blue-4);
`;

const ProgramInfo = ({ planId, confId }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Info>
        <Trans
          i18nKey="plan_info:allows_embed"
          values={{ plan: t(`conf_plans:${planId}`) }}
          components={[
            <PlanBadge key={0} />,
            <Link
              key={1}
              href={`${process.env.REACT_APP_EVENTS_URL}/event/${confId}`}
              target="_blank"
            />,
            <Link
              key={2}
              href={`${process.env.REACT_APP_AGENDA_URL}/${confId}`}
              target="_blank"
            />
          ]}
        />
        <Button
          style={{ marginLeft: 12}}
          href={FAQ_EMBED_AGENDA_LINK}
          target="_blank"
        >
          {t('common:learn_more')}
        </Button>
      </Info>
    </Wrapper>
  );
};

export default ProgramInfo;
