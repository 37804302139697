import types from '../types';
import { requestStatus } from '../request-status';

const initialState = {
  list: [],
  status: requestStatus.UNDONE,
  error: ''
};

const getUpdatedSpeakers = (list, payload) => {
  if (!Array.isArray(payload)) {
    return list.map(speaker => speaker.id === payload.id ? payload : speaker);
  }

  return list.map(speaker => {
    const updatedSpeaker = payload.find(item => item.id === speaker.id);

    return updatedSpeaker || speaker;
  });
};

const speakers = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_SPEAKERS_SUCCESS: {
      const payload = action.payload;
      const newSpeakers = Array.isArray(payload) ? payload : [payload];

      return {
        ...state,
        status: requestStatus.DONE,
        list: state.list.concat(newSpeakers),
        error: ''
      };
    }

    case types.UPDATE_SPEAKERS_SUCCESS:
      return {
        ...state,
        list: getUpdatedSpeakers(state.list, action.payload),
        status: requestStatus.DONE,
        error: ''
      };

    case types.UPDATE_SPEAKERS_REQUEST:
    case types.CREATE_SPEAKERS_REQUEST:
    case types.FIND_ALL_SPEAKERS_REQUEST:
      return {
        ...state,
        status: requestStatus.WAITING,
        error: ''
      };

    case types.FIND_ALL_SPEAKERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: requestStatus.DONE,
        error: ''
      };

    case types.UPDATE_SPEAKERS_ERROR:
    case types.CREATE_SPEAKERS_ERROR:
    case types.FIND_ALL_SPEAKERS_ERROR:
      return {
        ...state,
        status: requestStatus.DONE,
        error: action.payload
      };

    case types.DELETE_SPEAKER:
      return {
        ...state,
        list: state.list.filter(speaker => speaker.id !== action.payload)
      };

    default:
      return state;
  }
};

export default speakers;
