import { handleAuthentication } from '../../services/AuthService';
import types from '../types';

const handleAuthenticationCallback = () => ({
  type: types.HANDLE_AUTHENTICATION_CALLBACK
});

const userProfileLoaded = user => ({
  type: types.USER_PROFILE_LOADED,
  payload: user
});

export const performAuthentication = (lang) => dispatch => {
  dispatch(handleAuthenticationCallback());

  return handleAuthentication(lang).then(user => {
    return dispatch(userProfileLoaded(user));
  });
};
