import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { useDispatch } from 'redux-react-hook';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import Deletable from '../utils/deletable';
import { deleteConf, duplicateConf, findAllConfs } from '../../store/actions/confs';
import { addFlag } from '../../store/actions/flags';
import DuplicateEventConfirm from './home/duplicate-event-confirm';

const ConfButtons =  ({ event, history, style }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false);

  const onDelete = () => {
    dispatch(deleteConf(event))
      .then(() => {
        if (history) {
          history.push('/');
        }
      })
      .catch((err) => {
        dispatch(
          addFlag({
            description: '',
            appearance: 'error',
            icon: <Error label="Error icon" primaryColor={colors.R300} />,
            title: err.message,
            timeout: 5000
          })
        );
      });
  };

  const duplicateEvent = (rooms, speakers, miniApps, sessions) => {
    dispatch(duplicateConf(event.id, rooms, speakers, miniApps, sessions))
      .then(() => {
        dispatch(
          addFlag({
            appearance: 'success',
            title: 'confs:actions:confirm_duplicate:success',
            timeout: 5000,
          })
        );

        if (history) {
          history.push('/');
        } else {
          dispatch(findAllConfs());
        }
      })
      .catch(() => {
        dispatch(
          addFlag({
            description: '',
            appearance: 'error',
            icon: <Error label="Error icon" primaryColor={colors.R300} />,
            title: 'confs:actions:confirm_duplicate:error'
          })
        );
      });
  };

  return (
    <div style={style}>
      <Button
        data-tip={t('confs:actions:confirm_duplicate:duplicate')}
        appearance="subtle"
        onClick={() => setDuplicateModalIsOpen(true)}
        iconBefore={<CopyIcon label="copy-icon" />}
      />
      <Deletable
        onDelete={onDelete}
        modalHeading={t('confs:actions:confirm_remove:header')}
        modalContent={t('confs:actions:confirm_remove:warning', {
          name: event.name
        })}
        noButton
      >
        <Button
          appearance="subtle"
          data-tip={t('confs:actions:remove')}
          iconBefore={<TrashIcon label="trash-icon" primaryColor="var(--red-2)" />}
        />
      </Deletable>
      <DuplicateEventConfirm
        open={duplicateModalIsOpen}
        onConfirm={(rooms, speakers, miniApps, sessions) => duplicateEvent(rooms, speakers, miniApps, sessions)}
        onClose={() => setDuplicateModalIsOpen(false)}
      />
    </div>
  );
};

export default ConfButtons;
